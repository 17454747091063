import React, { useState } from 'react';
import styled from 'styled-components';

const CookieSettingsModal = ({ isOpen, onClose, setIsVisible }) => {
    const [analyticsEnabled, setAnalyticsEnabled] = useState(true);

    if (!isOpen) return null;

    const handleSavePreferences = () => {
        const preferences = {
            analytics: analyticsEnabled,
        };
        localStorage.setItem('drillup_cookie_preferences', JSON.stringify(preferences));
        localStorage.setItem('drillup_cookie_consent', 'customized');
        onClose();
        initializeCookies(preferences);
        setIsVisible(false);
    };

    const initializeCookies = (preferences) => {
        if (preferences.analytics) {
            // Initialize analytics cookies (e.g., Google Analytics)
            if (typeof window.gtag === 'undefined') {
                const script = document.createElement('script');
                script.async = true;
                script.src = `https://www.googletagmanager.com/gtag/js?id=G-0Z71VZSYHC`; // Replace with your GA ID
                document.head.appendChild(script);

                window.dataLayer = window.dataLayer || [];
                function gtag() {
                    window.dataLayer.push(arguments);
                }

                window.gtag = gtag;
                window.gtag('js', new Date());
                window.gtag('config', 'G-0Z71VZSYHC', { anonymize_ip: true });
            }
        } else {
            // Remove analytics cookies
            const cookies = document.cookie.split('; ');
            cookies.forEach((cookie) => {
                const [name] = cookie.split('=');
                if (name.startsWith('_ga') || name.startsWith('_gid') || name.startsWith('_gat')) {
                    document.cookie = `${name}=; Max-Age=0; path=/;`;
                }
            });
            // Remove the Google Analytics script
            const gaScript = document.querySelector(`script[src*="googletagmanager.com/gtag/js"]`);
            if (gaScript) {
                gaScript.parentNode.removeChild(gaScript);
            }
            // Clear the dataLayer
            if (window.dataLayer) {
                window.dataLayer = [];
            }
        }
    };

    return (
        <ModalOverlay>
            <ModalContent>
                <ModalHeader>
                    <ModalTitle>Cookie Settings</ModalTitle>
                    <CloseButton onClick={onClose}>&times;</CloseButton>
                </ModalHeader>
                <ModalBody>
                    <p>You can customize your cookie preferences below:</p>
                    <Form>
                        <FormGroup>
                            <Label>
                                <Checkbox type="checkbox" disabled checked />
                                Essential Cookies (Always Active)
                            </Label>
                        </FormGroup>
                        <FormGroup>
                            <Label>
                                <Checkbox
                                    type="checkbox"
                                    checked={analyticsEnabled}
                                    onChange={(e) => setAnalyticsEnabled(e.target.checked)}
                                />
                                Analytics Cookies
                            </Label>
                        </FormGroup>
                        <ButtonWrapper>
                            <SaveButton type="button" onClick={handleSavePreferences}>
                                Save Preferences
                            </SaveButton>
                        </ButtonWrapper>
                    </Form>
                </ModalBody>
            </ModalContent>
        </ModalOverlay>
    );
};

export default CookieSettingsModal;

// Styled Components
const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1500;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ModalContent = styled.div`
    background-color: #ffffff;
    border-radius: 10px;
    width: 90%;
    max-width: 500px;
    padding: 20px;
    position: relative;
`;

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ModalTitle = styled.h2`
    font-size: 24px;
    color: #2d2d2d;
`;

const CloseButton = styled.button`
    background: none;
    border: none;
    font-size: 28px;
    cursor: pointer;
    color: #2d2d2d;
`;

const ModalBody = styled.div`
    margin-top: 15px;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

const FormGroup = styled.div`
    margin-bottom: 15px;
`;

const Label = styled.label`
    font-size: 16px;
    color: #2d2d2d;
    display: flex;
    align-items: center;
`;

const Checkbox = styled.input`
    margin-right: 10px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const SaveButton = styled.button`
    background-color: #009379;
    color: #ffffff;
    padding: 12px 20px;
    border-radius: 30px;
    border: none;
    font-weight: 600;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease, transform 0.2s ease;

    &:hover {
        background-color: #007b64;
        transform: translateY(-3px);
    }
`;
