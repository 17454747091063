import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CookieSettingsModal from './CookieSettingsModal'; // Import the modal

const CookieConsent = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const consent = localStorage.getItem('drillup_cookie_consent');
        if (!consent) {
            setIsVisible(true);
        } else {
            // Initialize cookies based on stored preferences
            const preferences = JSON.parse(localStorage.getItem('drillup_cookie_preferences'));
            initializeCookies(preferences);
        }
    }, []);

    const handleAcceptAll = () => {
        localStorage.setItem('drillup_cookie_consent', 'all');
        localStorage.setItem('drillup_cookie_preferences', JSON.stringify({ analytics: true }));
        setIsVisible(false);
        initializeCookies({ analytics: true });
    };

    const handleCustomize = () => {
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const initializeCookies = (preferences) => {
        if (preferences.analytics) {
            // Initialize analytics cookies (e.g., Google Analytics)
            if (typeof window.gtag === 'undefined') {
                const script = document.createElement('script');
                script.async = true;
                script.src = `https://www.googletagmanager.com/gtag/js?id=G-0Z71VZSYHC`; // Replace with your GA ID
                document.head.appendChild(script);

                window.dataLayer = window.dataLayer || [];
                function gtag() {
                    window.dataLayer.push(arguments);
                }

                window.gtag = gtag;
                window.gtag('js', new Date());
                window.gtag('config', 'G-0Z71VZSYHC', { anonymize_ip: true });
            }
        } else {
            // Remove analytics cookies
            const cookies = document.cookie.split('; ');
            cookies.forEach((cookie) => {
                const [name] = cookie.split('=');
                if (name.startsWith('_ga') || name.startsWith('_gid') || name.startsWith('_gat')) {
                    document.cookie = `${name}=; Max-Age=0; path=/;`;
                }
            });
            // Remove the Google Analytics script
            const gaScript = document.querySelector(`script[src*="googletagmanager.com/gtag/js"]`);
            if (gaScript) {
                gaScript.parentNode.removeChild(gaScript);
            }
            // Clear the dataLayer
            if (window.dataLayer) {
                window.dataLayer = [];
            }
        }
    };

    if (!isVisible) {
        return null;
    }

    return (
        <>
            <CookieBanner>
                <BannerText>
                    We use cookies to enhance your experience and analyze site performance. By clicking "Accept All Cookies", you consent to our use of cookies.&nbsp;
                    <PrivacyLink href="/cookie-policy.html" target="_blank" rel="noopener noreferrer">
                        Learn More
                    </PrivacyLink>
                </BannerText>
                <ButtonWrapper>
                    <AcceptButton onClick={handleAcceptAll}>
                        Accept All Cookies
                    </AcceptButton>
                    <CustomizeButton onClick={handleCustomize}>
                        Customize Settings
                    </CustomizeButton>
                </ButtonWrapper>
            </CookieBanner>
            <CookieSettingsModal setIsVisible={setIsVisible} isOpen={isModalOpen} onClose={handleModalClose} />
        </>
    );
};

export default CookieConsent;

// Styled Components
const CookieBanner = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #2d2d2d; /* Dark background */
    color: #ffffff; /* White text */
    padding: 15px 20px;
    text-align: center;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 480px) {
        padding: 10px;
    }
`;

const BannerText = styled.p`
    font-size: 16px;
    margin: 0 auto; /* Center the text */
    max-width: 800px;
    text-align: center; /* Ensure text is centered */
    padding: 0 20px; /* Add padding on both sides */

    @media (max-width: 480px) {
        font-size: 14px;
        max-width: 100%;
        padding: 0 15px; /* Increase padding on mobile */
    }
`;

const PrivacyLink = styled.a`
    color: #009379; /* Use your primary color */
    text-decoration: underline;

    &:hover {
        color: #007b64;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    gap: 15px;
    margin-top: 15px;
    flex-wrap: wrap;
    justify-content: center;

    @media (max-width: 480px) {
        flex-direction: column;
        gap: 10px;
        width: 100%;
        padding: 0 10px;
        box-sizing: border-box;
    }
`;

const Button = styled.button`
    padding: 12px 25px;
    border-radius: 30px;
    border: none;
    font-weight: 600;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease, transform 0.2s ease;

    &:hover {
        transform: translateY(-3px);
    }

    @media (max-width: 480px) {
        width: 100%;
        font-size: 14px;
        padding: 10px 20px;
    }
`;

const AcceptButton = styled(Button)`
    background-color: #009379; /* Primary color */
    color: #ffffff;

    &:hover {
        background-color: #007b64;
    }
`;

const CustomizeButton = styled(Button)`
    background-color: transparent;
    color: #ffffff;
    border: 2px solid #ffffff;

    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
`;
