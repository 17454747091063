import React, { useEffect, useRef } from "react";
import styled from "styled-components";


const testimonials = [
    {
        id: 1,
        quote:
            "The personalized coaching and support I received from DrillUp were instrumental in landing my dream job. The process was seamless, and I felt prepared every step of the way.",
        avatar: 'images/person1.webp',
        name: "Robert H.",
    },
    {
        id: 2,
        quote:
            "DrillUp's guidance made a huge difference in my job search. The pre-designed strategies and mock interviews helped me approach each interview with confidence and clarity.",
        avatar: 'images/person2.webp',
        name: "Lina S.",
    },
    {
        id: 3,
        quote:
            "The comprehensive support from DrillUp was a game-changer. From coaching to relocation assistance, every aspect of their service was top-notch and contributed to my successful career move.",
        avatar: 'images/person3.webp',
        name: "Darius W.",
    },
    {
        id: 4,
        quote:
            "I couldn't be happier with the coaching I received. The advice was spot on, and I felt more confident walking into interviews.",
        avatar: 'images/person4.webp',
        name: "Isabella M.",
    },
    {
        id: 5,
        quote:
            "Thanks to DrillUp, I secured my role at a leading tech company in Europe. Their support made all the difference.",
        avatar: 'images/person5.webp',
        name: "Kai L.",
    },
    {
        id: 6,
        quote:
            "The insights I gained from the coaching sessions were invaluable. It was an excellent investment in my career.",
        avatar: 'images/person6.webp',
        name: "Amina K.",
    },
    {
        id: 7,
        quote:
            "DrillUp’s coaching service gave me the edge I needed to stand out in a competitive market. Highly recommend!",
        avatar: 'images/person7.webp',
        name: "Anika P.",
    },
    {
        id: 8,
        quote:
            "I’m so grateful for the guidance and support from DrillUp. It truly made a difference in my job search.",
        avatar: 'images/person8.webp',
        name: "Miguel R.",
    },
    {
        id: 9,
        quote:
            "The coaching sessions were tailored to my needs, and the feedback was incredibly helpful. I’m now more confident in my career path.",
        avatar: 'images/person9.webp',
        name: "Arjun B.",
    },
    {
        id: 10,
        quote:
            "I was impressed by the level of detail and care DrillUp put into their coaching program. It really prepared me for the challenges ahead.",
        avatar: 'images/person10.webp',
        name: "Maya N.",
    },
    {
        id: 11,
        quote:
            "I landed my dream job thanks to the comprehensive support from DrillUp. I couldn’t have done it without them!",
        avatar: 'images/person11.webp',
        name: "Jamal E.",
    },
    {
        id: 12,
        quote:
            "The process was smooth and the coaching was very effective. I’m now more confident in my abilities and ready to take on new challenges.",
        avatar: 'images/person12.webp',
        name: "Hassan A.",
    },
    {
        id: 13,
        quote:
            "The structured approach and personalized attention I received from DrillUp made a significant difference in my job search.",
        avatar: 'images/person13.webp',
        name: "Samuel O.",
    },
    {
        id: 14,
        quote:
            "DrillUp's expertise in the job market gave me a unique perspective. Their coaching helped me navigate the process with ease.",
        avatar: 'images/person14.webp',
        name: "Olivia J.",
    },
    {
        id: 15,
        quote:
            "The feedback and insights I received from DrillUp were invaluable. They really know what they’re doing.",
        avatar: 'images/person15.webp',
        name: "David F.",
    },
    {
        id: 16,
        quote:
            "Thanks to DrillUp, I now have a clear direction for my career and the confidence to pursue it.",
        avatar: 'images/person16.webp',
        name: "Bjorn Z.",
    },
    {
        id: 17,
        quote:
            "The coaching from DrillUp exceeded my expectations. I felt well-prepared for every step of the job search process.",
        avatar: 'images/person17.webp',
        name: "Sophia K.",
    },
    {
        id: 18,
        quote:
            "DrillUp provided the structure and support I needed to succeed. Their expertise really made a difference in my career.",
        avatar: 'images/person18.webp',
        name: "Gabriella C.",
    },
    {
        id: 19,
        quote:
            "The coaching process was detailed and tailored to my needs. I now have a better understanding of how to position myself in the job market.",
        avatar: 'images/person19.webp',
        name: "Joshua L.",
    },
    {
        id: 20,
        quote:
            "I highly recommend DrillUp for anyone looking to advance their career. The coaching was exactly what I needed to get to the next level.",
        avatar: 'images/person20.webp',
        name: "Emily B.",
    },
    {
        id: 21,
        quote:
            "The coaching I received from DrillUp was top-notch. It really helped me gain the confidence I needed to succeed.",
        avatar: 'images/person21.webp',
        name: "Emma S.",
    },
    {
        id: 22,
        quote:
            "DrillUp's coaching was the best investment I made in my career. Their insights and guidance were invaluable.",
        avatar: 'images/person22.webp',
        name: "Fiona G.",
    },
    {
        id: 23,
        quote:
            "I was thoroughly impressed by the level of care and attention I received from DrillUp. They really go above and beyond.",
        avatar: 'images/person23.webp',
        name: "Lea M.",
    },
    {
        id: 24,
        quote:
            "The support I received from DrillUp was outstanding. It made the entire process of finding a job in Europe so much easier.",
        avatar: 'images/person24.webp',
        name: "John D.",
    },
];

const Testimonials = () => {
    const carouselRef = useRef(null);

    useEffect(() => {
        const interval = setInterval(() => {
            if (carouselRef.current) {
                carouselRef.current.scrollLeft += 350; // Adjust this value as needed
            }
        }, 3000); // Adjust this value for scroll speed

        return () => clearInterval(interval);
    }, []);

    return (
        <TestimonialSection>
            <Title>Success Stories</Title>
            <Subtitle>
                Discover how DrillUp has empowered professionals to transition into fulfilling careers across Europe.
            </Subtitle>
            <Carousel ref={carouselRef}>
                {testimonials.map((testimonial) => (
                    <TestimonialCard key={testimonial.id}>
                        <Avatar src={testimonial.avatar} alt="Client Avatar" />
                        <Name>{testimonial.name}</Name>
                        <Quote>{testimonial.quote}</Quote>
                    </TestimonialCard>
                ))}
            </Carousel>
        </TestimonialSection>
    );
};

const TestimonialSection = styled.section`
    background-color: #f8f9ff;
    padding: 40px 20px 20px 20px;
    text-align: center;

    @media (max-width: 768px) {
        padding: 60px 20px;
    }
`;

const Title = styled.h2`
    color: #009379;
    letter-spacing: -0.76px;
    margin-top: 20px;
    font: 700 38px/130% Poppins, sans-serif;

    @media (max-width: 768px) {
        font-size: 32px;
    }
`;

const Subtitle = styled.p`
    color: #2d2d2d;
    font: 400 20px/1.5 'Mulish', sans-serif; /* Increased font size and adjusted line height */
    margin: 20px auto 50px; /* Adjusted spacing for better balance */
    max-width: 800px; /* Set a maximum width to keep the text readable on larger screens */
    font-style: italic; /* Added a slight italic style for emphasis */
    @media (max-width: 991px) {
        font-size: 18px; /* Slightly smaller on mobile */
        margin-bottom: 40px;
    }
`;

const Carousel = styled.div`
    display: flex;
    overflow-x: scroll;
    scroll-behavior: smooth;
    gap: 20px;
    padding: 20px 0;
    margin: 40px 0;

    &::-webkit-scrollbar {
        display: none;
    }
`;

const TestimonialCard = styled.article`
    background-color: #fff;
    border: 1px solid rgba(229, 244, 242, 1);
    border-radius: 20px;
    box-shadow: 0px 10px 20px rgba(51, 102, 255, 0.05);
    display: flex;
    flex-direction: column;
    padding: 30px 20px;
    min-width: 350px;
    max-width: 350px;
    text-align: center;
    flex: 1;
    justify-content: space-between;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-10px);
        box-shadow: 0 15px 25px rgba(51, 102, 255, 0.1);
    }

    @media (max-width: 768px) {
        min-width: 280px;
        padding: 20px;
    }
`;

const Avatar = styled.img`
    aspect-ratio: 1;
    border-radius: 50%;
    object-fit: cover;
    width: 56px;
    align-self: center;
`;

const Name = styled.p`
    font-weight: 600;
    font-size: 1rem;
    margin-top: 10px;
    color: #2d2d2d;
`;

const Quote = styled.blockquote`
    color: #2d2d2d;
    margin-top: 20px;
    font: 400 16px/24px Mulish, sans-serif;
`;

export default Testimonials;