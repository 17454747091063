import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

const Header = ({ scrollToPricing, scrollToContact, scrollToFeatures }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navRef = useRef(null);
    const toggleButtonRef = useRef(null);

    const handleNavClick = (label, componentName) => {
        if (typeof window.gtag === 'function') {
            window.gtag('event', 'nav_click', {
                event_category: 'navigation',
                event_label: label,
                component_name: componentName,
            });
        } else {
            console.warn('Google Analytics is not initialized');
        }
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleClickOutside = (event) => {
        if (
            navRef.current &&
            !navRef.current.contains(event.target) &&
            toggleButtonRef.current &&
            !toggleButtonRef.current.contains(event.target)
        ) {
            setIsMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            <HeaderWrapper>
                <LogoText>DrillUp</LogoText>
                <MenuToggle ref={toggleButtonRef} onClick={toggleMenu} $isOpen={isMenuOpen}>
                    <span></span>
                    <span></span>
                    <span></span>
                </MenuToggle>
                <Nav ref={navRef} $isOpen={isMenuOpen}>
                    <NavItem
                        onClick={(event) => {
                            scrollToFeatures(event);
                            handleNavClick('learn_more_about_us', 'header');
                            setIsMenuOpen(false); // Close menu on click
                        }}
                        key="help"
                        href="#"
                    >
                        Learn More About Us
                    </NavItem>
                    <NavItem
                        onClick={(event) => {
                            scrollToContact(event);
                            handleNavClick('contact_us', 'header');
                            setIsMenuOpen(false); // Close menu on click
                        }}
                        key="contact"
                        href="#"
                    >
                        Contact us
                    </NavItem>
                    <GetStartedButton
                        onClick={(event) => {
                            scrollToPricing(event);
                            handleNavClick('start_your_journey', 'header');
                            setIsMenuOpen(false); // Close menu on click
                        }}
                    >
                        <span>Start Your Journey</span>
                    </GetStartedButton>
                </Nav>
            </HeaderWrapper>
            {isMenuOpen && <Overlay onClick={() => setIsMenuOpen(false)} />}
        </>
    );
};

export default Header;

// Styled Components
const HeaderWrapper = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 50px;
    background-color: #f8f9ff;
    position: relative;
    z-index: 1001;

    @media (max-width: 991px) {
        padding: 20px;
    }
`;

const LogoText = styled.span`
    font-size: 32px;
    font-weight: 900;
    color: #009379;
    line-height: 1.8;
    font-family: Poppins, sans-serif;
`;

const Nav = styled.nav`
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;

    @media (max-width: 991px) {
        flex-direction: column;
        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
        background-color: #f8f9ff;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
        display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
        z-index: 1000;
    }
`;

const NavItem = styled.a`
    color: #009379;
    font-family: Poppins, sans-serif;
    text-decoration: none;

    @media (max-width: 991px) {
        padding: 10px 0;
        width: 100%;
        text-align: center;
    }
`;

const GetStartedButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 16px 50px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    background-color: #009379;
    color: #fff;
    font-family: Poppins, sans-serif;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;

    &:hover {
        transform: translateY(-3px);
    }

    @media (max-width: 991px) {
        padding: 16px 20px;
        width: 100%;
        text-align: center;
    }
`;

const MenuToggle = styled.div`
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 24px;
    height: 18px;
    cursor: pointer;

    span {
        display: block;
        height: 2px;
        background-color: #2d2d2d;
        border-radius: 2px;
        transition: transform 0.3s ease, background-color 0.3s ease;

        &:nth-child(1) {
            transform: ${({ $isOpen }) => ($isOpen ? 'rotate(45deg) translate(5px, 5px)' : 'none')};
        }

        &:nth-child(2) {
            opacity: ${({ $isOpen }) => ($isOpen ? 0 : 1)};
        }

        &:nth-child(3) {
            transform: ${({ $isOpen }) => ($isOpen ? 'rotate(-45deg) translate(5px, -5px)' : 'none')};
        }
    }

    @media (max-width: 991px) {
        display: flex;
    }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;
