export async function sendDataToLambda(data) {
    const endpoint = "https://4u5rnu3fhg3inueqr7kjdnq7si0lyqjv.lambda-url.eu-central-1.on.aws/";

    try {
        const response = await fetch(endpoint, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });

        return response.status;
    } catch (error) {
        console.error('Error:', error);
        return error.status || 500;
    }
}