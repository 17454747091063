import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";

const Toaster = ({ message, show, onClose }) => {
    useEffect(() => {
        if (show) {
            const timer = setTimeout(onClose, 3500); // Automatically close after 3 seconds
            return () => clearTimeout(timer);
        }
    }, [show, onClose]);

    if (!show) return null;

    return (
        <ToasterWrapper>
            <ToasterMessage onClick={onClose}>
                {message}
            </ToasterMessage>
        </ToasterWrapper>
    );
};

const slideUp = keyframes`
    from {
        transform: translate(-50%, 100%); /* Start below the screen and centered */
        opacity: 0;
    }
    to {
        transform: translate(-50%, 0); /* End at its normal position */
        opacity: 1;
    }
`;

const ToasterWrapper = styled.div`
    position: fixed;
    bottom: 80px; /* Padding from bottom of the page */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Maintain center position */
    z-index: 1000;
    width: 90%;
    max-width: 400px;
    animation: ${slideUp} 0.5s ease-out; /* Animation for slide-up effect */

    @media (max-width: 768px) {
        bottom: 20px; /* Reduced bottom padding for mobile screens */
    }
`;

const ToasterMessage = styled.div`
    background-color: #009379; /* Replace with your company primary color */
    color: #ffffff; /* Replace with your company text color */
    padding: 30px 20px;
    border-radius: 30px; /* Adjust for your company shape */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    transition: transform 0.3s ease, opacity 0.3s ease;

    &:hover {
        transform: translateY(-2px);
    }

    @media (max-width: 768px) {
        font-size: 14px;
        padding: 12px 16px;
    }
`;

export default Toaster;
