import React, { forwardRef } from "react";
import styled from "styled-components";

const features = [
    {
        icon: "images/Tailored_Coaching.svg",
        title: "Personalized Interview Prep",
        description: [
            "Tailored coaching for behavioral, architectural, and system design interviews. Whether you’re aiming for your first tech role or your next promotion, we’ve got you covered.",
        ],
    },
    {
        icon: "images/Career_Success.svg",
        title: "Career Advancement Coaching",
        description: [
            "Navigate your career with expert advice on how to showcase your strengths, achieve promotions, and excel in your current role.",
        ],
    },
    {
        icon: "images/Relocation_Assistance.svg",
        title: "End-to-End Career Guidance",
        description: [
            "From CV reviews to mock interviews and beyond! DrillUp offers comprehensive support to ensure your success.",
        ],
    },
];

const Features = forwardRef((props, ref) => {
    return (
        <FeaturesSection ref={ref}>
            <SectionTitle>Why Choose Us?</SectionTitle>
            <SectionDescription>
                At DrillUp, we empower tech professionals to succeed in Europe’s top companies. Our expert coaching and relocation support are tailored to mid-level to senior roles, ensuring you’re confident, prepared, and supported every step of the way. Choose DrillUp for personalized guidance that drives your career forward.
            </SectionDescription>
            <FeaturesGrid>
                {features.map((feature, index) => (
                    <FeatureCard key={index}>
                        <IconWrapper
                            $backgroundColor={
                                index === 0
                                    ? "rgba(255, 98, 80, 0.2)"
                                    : index === 1
                                        ? "rgba(0, 147, 121, 0.2)"
                                        : "rgba(248, 213, 126, 0.2)"
                            }
                        >
                            <img src={feature.icon} alt={feature.title} />
                        </IconWrapper>
                        <FeatureTitle>{feature.title}</FeatureTitle>
                        <FeatureDescription>
                            {feature.description.map((line, index) => (
                                <p key={index}>{line}</p>
                            ))}
                        </FeatureDescription>
                    </FeatureCard>
                ))}
            </FeaturesGrid>
        </FeaturesSection>
    );
});

// Styled Components
const FeaturesSection = styled.section`
    background-color: #f8f9ff;
    padding: 10px 20px 20px 20px;
    text-align: center;
`;

const SectionTitle = styled.h2`
    color: #000;
    font-size: 2.4rem;
    font-weight: 700;
    margin-top: 20px;
    font-family: Poppins, sans-serif;

    @media (max-width: 768px) {
        font-size: 2rem;
    }
`;

const SectionDescription = styled.p`
    color: #2d2d2d;
    font: 400 20px/1.5 'Mulish', sans-serif; /* Increased font size and adjusted line height */
    margin: 20px auto 50px; /* Adjusted spacing for better balance */
    max-width: 800px; /* Set a maximum width to keep the text readable on larger screens */
    font-style: italic; /* Added a slight italic style for emphasis */
    @media (max-width: 991px) {
        font-size: 18px; /* Slightly smaller on mobile */
        margin-bottom: 40px;
    }
`;

const FeaturesGrid = styled.div`
    display: flex;
    justify-content: center;
    gap: 40px;
    margin: 60px 0;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

const FeatureCard = styled.article`
    background-color: #fff;
    border: 1px solid rgba(229, 244, 242, 1);
    border-radius: 20px;
    box-shadow: 0px 10px 20px rgba(51, 102, 255, 0.05);
    display: flex;
    flex-direction: column;
    padding: 30px 20px;
    width: 100%;
    max-width: 350px;
    text-align: left;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-10px);
        box-shadow: 0 15px 25px rgba(51, 102, 255, 0.1);
    }

    @media (max-width: 768px) {
        margin-top: 20px;
    }
`;

const IconWrapper = styled.div`
    background-color: ${(props) => props.$backgroundColor};
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    margin: 0 auto;
`;

const FeatureTitle = styled.h3`
    color: #2d2d2d;
    font-size: 1.75rem;
    font-weight: 600;
    margin-top: 20px;
    font-family: Poppins, sans-serif;
    text-align: center;

    @media (max-width: 768px) {
        text-align: center;
    }
`;

const FeatureDescription = styled.div`
    color: #2d2d2d;
    font-size: 1rem;
    line-height: 1.5;
    font-family: Mulish, sans-serif;
    margin-top: 20px;
    text-align: center;

    ul {
        list-style-type: disc;
        padding-left: 20px;
        margin-top: 10px;
        text-align: left;
    }

    li {
        margin-bottom: 5px;
    }

    @media (max-width: 768px) {
        ul {
            text-align: center;
        }
    }
`;

export default Features;
