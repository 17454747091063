import React, { useState, forwardRef } from 'react';
import styled from 'styled-components';
import { FaChevronDown } from 'react-icons/fa';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

// FAQ Data
const faqs = [
    {
        question: "What Makes Your Consulting Agency Different from Others?",
        answer: `At our agency, we're not just consultants—we're your partners in success. We understand that every engineer has a unique story, and we're genuinely excited to be a part of yours. Here's what sets us apart:

- **Personalized Coaching Plans:** We take the time to get to know you, your goals, and your challenges. By tailoring our strategies to your specific needs, we ensure that you're not just another face in the crowd but a valued individual on a path to success.

- **Experienced and Supportive Consultants:** Our team consists of professionals who've been in your shoes. They've worked at top tech companies and faced the same hurdles you're encountering. They're here to share their firsthand experiences and insights to help you navigate your journey more smoothly.

- **Comprehensive, Holistic Services:** Whether you're looking to enhance your technical skills, develop leadership qualities, or plan your next career move, we've got you covered. We're here to support every facet of your professional growth.

- **A Shared Commitment to Your Success:** Your achievements are our greatest reward. We're deeply invested in your progress and are here to provide the encouragement, tools, and guidance you need to excel.

We know the tech industry can be challenging, but you don't have to face it alone. We're here to walk beside you every step of the way, celebrating your victories and helping you overcome obstacles. Let's reach new heights together!`,
        label: "what_makes_your_agency_different",
    },
    {
        question: "What Are Mock Technical Interviews and How Can They Accelerate My Engineering Career?",
        answer: `Mock technical interviews are simulated interview sessions designed to mirror real-world technical interviews you might face at top tech companies.

- **Experience Real Interview Scenarios:** Practice coding challenges, system design questions, and problem-solving tasks similar to those asked by companies like Google, Amazon, and Microsoft.
- **Receive Expert Feedback:** Get personalized insights from seasoned engineers who have conducted or undergone numerous interviews.
- **Identify Strengths and Weaknesses:** Understand your technical proficiencies and areas that need improvement.
- **Boost Confidence:** Reduce anxiety by familiarizing yourself with the interview format and expectations.

Our mock interviews are tailored to your specific role and experience level, ensuring you gain the most relevant preparation.`,
        label: "what_are_mock_technical_interviews",
    },
    {
        question: "How Does Technical Coaching Benefit Mid to Senior-Level Engineers and Tech Leads?",
        answer: `Technical coaching provides personalized guidance to enhance your technical skills, leadership abilities, and strategic thinking.

- **Skill Enhancement:** Update your knowledge on the latest technologies, programming languages, and best practices.
- **Leadership Development:** Learn how to lead teams effectively, manage projects, and make strategic decisions.
- **Problem-Solving Techniques:** Improve your ability to tackle complex technical challenges.
- **Career Advancement:** Position yourself for promotions and new opportunities by demonstrating continuous growth.

Our experienced coaches work with you to create a customized development plan aligned with your career goals.`,
        label: "how_does_technical_coaching_benefit_engineers",
    },
    {
        question: "What Is System Design Coaching and Why Is It Crucial for Senior Engineers and Engineering Managers?",
        answer: `System design coaching focuses on teaching you how to design scalable, efficient, and robust systems—a key skill for higher-level engineering roles.

- **Master Architectural Principles:** Understand how to build systems that can handle millions of users.
- **Practical Experience:** Work through real-world scenarios to apply design patterns and best practices.
- **Performance Optimization:** Learn techniques to improve system performance and reliability.
- **Interview Preparation:** Get ready for system design interviews, which are critical for senior positions.

This coaching is essential for those looking to move into roles that require a deep understanding of system architecture.`,
        label: "what_is_system_design_coaching_and_why_crucial",
    },
    {
        question: "How Can Career Development Coaching Help Me Reach My Professional Goals?",
        answer: `Career development coaching offers strategic guidance to help you navigate your career path effectively.

- **Goal Setting:** Define clear, achievable career objectives.
- **Skill Gap Analysis:** Identify the skills you need to acquire or improve.
- **Personal Branding:** Learn how to market yourself within your industry.
- **Networking Strategies:** Build meaningful professional relationships.

By focusing on both short-term and long-term goals, our coaching helps you create a roadmap for career success.`,
        label: "how_can_career_development_coaching_help_me",
    },
    {
        question: "What Are the Benefits of Salary Negotiation Coaching for Engineers?",
        answer: `Salary negotiation coaching equips you with the strategies and confidence to secure a compensation package that reflects your value.

- **Market Research:** Understand industry salary benchmarks for your role and experience level.
- **Negotiation Techniques:** Learn effective tactics to negotiate salary, equity, and benefits.
- **Communication Skills:** Practice articulating your value proposition to employers.
- **Handling Offers:** Navigate multiple job offers and counteroffers professionally.

Our coaches guide you through the negotiation process to maximize your earning potential.`,
        label: "what_are_benefits_of_salary_negotiation_coaching",
    },
    {
        question: "Do You Offer Specialized Coaching for Technical Interviews at FAANG Companies?",
        answer: `Yes, we provide targeted preparation for technical interviews at top-tier tech companies.

- **Coding Challenges:** Practice problems that reflect the complexity and style of FAANG interviews.
- **Algorithm Mastery:** Deepen your understanding of data structures and algorithms.
- **System Design Questions:** Prepare for high-level design discussions typical in senior roles.
- **Behavioral Interviews:** Learn how to showcase your teamwork, leadership, and problem-solving skills.

Our materials and coaching sessions are continuously updated to align with the latest interview trends at these companies.`,
        label: "do_you_offer_specialized_coaching_for_faang",
    },
    {
        question: "How Does Leadership Coaching Support Engineering Managers and Tech Leads?",
        answer: `Leadership coaching enhances your ability to lead teams, manage projects, and drive organizational success.

- **Team Management:** Learn how to motivate and guide your team effectively.
- **Strategic Planning:** Develop skills in setting goals and aligning team efforts.
- **Conflict Resolution:** Master techniques to handle workplace challenges.
- **Emotional Intelligence:** Improve your interpersonal skills for better team dynamics.

This coaching is invaluable for those looking to excel in management positions within engineering.`,
        label: "how_does_leadership_coaching_support_managers",
    },
    {
        question: "Can You Help Me Improve My Coding Skills for Technical Assessments?",
        answer: `Absolutely. We offer comprehensive coding coaching to sharpen your programming abilities.

- **Language Proficiency:** Enhance your skills in languages like Python, Java, C++, and more.
- **Algorithm Practice:** Work through a variety of algorithmic problems to improve speed and efficiency.
- **Code Reviews:** Receive feedback on your coding style and practices.
- **Time Management:** Learn how to solve problems under time constraints.

Our goal is to help you become a more competent and confident coder, ready for any technical assessment.`,
        label: "can_you_help_improve_my_coding_skills",
    },
    {
        question: "What Resources Do You Provide for System Design Interview Preparation?",
        answer: `We offer extensive materials and coaching to help you excel in system design interviews.

- **Design Patterns:** Understand common patterns and when to apply them.
- **Scalability Concepts:** Learn how to design systems that can grow with user demand.
- **Real-World Scenarios:** Practice with case studies based on actual industry challenges.
- **Feedback Sessions:** Get personalized advice on your design approach and presentation.

These resources are critical for senior engineers and architects aiming to demonstrate their expertise.`,
        label: "what_resources_for_system_design_interview_prep",
    },
    {
        question: "How Do Mock Behavioral Interviews Enhance My Chances of Success?",
        answer: `Mock behavioral interviews prepare you to effectively communicate your experiences and soft skills.

- **Storytelling Techniques:** Learn how to present your experiences compellingly.
- **Common Questions:** Practice responses to frequently asked behavioral questions.
- **Feedback and Improvement:** Receive critiques to refine your answers.
- **Confidence Building:** Increase your comfort level in discussing your achievements and challenges.

Excelling in behavioral interviews can set you apart from other candidates by showcasing your holistic value to employers.`,
        label: "how_do_mock_behavioral_interviews_help",
    },
    {
        question: "Do You Offer Resume and LinkedIn Profile Optimization for Engineers?",
        answer: `Yes, we specialize in crafting resumes and LinkedIn profiles that highlight your technical expertise and achievements.

- **Keyword Optimization:** Ensure your resume passes Applicant Tracking Systems (ATS).
- **Professional Branding:** Present a consistent and compelling personal brand.
- **Achievement Focused:** Emphasize quantifiable results and impactful projects.
- **Tailored Content:** Customize your resume for specific roles or industries.

A well-optimized resume and LinkedIn profile can significantly increase your visibility to recruiters and hiring managers.`,
        label: "do_you_offer_resume_linkedin_optimization",
    },
    {
        question: "How Can I Prepare for Technical Presentations and Whiteboard Sessions?",
        answer: `We provide coaching to help you excel in technical presentations and whiteboard interviews.

- **Presentation Skills:** Learn how to communicate complex ideas clearly and effectively.
- **Visual Aids:** Use diagrams and illustrations to enhance understanding.
- **Interactive Engagement:** Practice engaging with your audience or interviewers.
- **Feedback Mechanisms:** Receive constructive critiques to improve your delivery.

These skills are essential for roles that require frequent presentations or collaborative problem-solving.`,
        label: "how_prepare_for_technical_presentations",
    },
    {
        question: "How Do I Get Started with Your Coaching Services?",
        answer: `Beginning your journey with us is straightforward.

1. **Contact Us:** Reach out via our website or email to express your interest.
2. **Initial Consultation:** Schedule a free session to discuss your goals and how we can assist.
3. **Customized Plan:** We'll develop a coaching plan tailored to your needs.
4. **Start Coaching:** Engage in sessions at your convenience, either online or in-person.
5. **Achieve Your Goals:** We'll support you every step of the way towards your career objectives.

We're excited to help you reach new heights in your engineering career.`,
        label: "how_do_i_get_started",
    },
    {
        question: "How Do You Ensure Confidentiality and Data Privacy?",
        answer: `Your trust is paramount to us, and we take confidentiality seriously.

- **Secure Communication:** All sessions and communications are conducted through encrypted channels.
- **Privacy Agreements:** We adhere to strict confidentiality agreements.
- **Data Protection:** Personal information is stored securely and used solely for coaching purposes.
- **Professional Ethics:** Our consultants follow ethical guidelines to maintain your privacy.

Rest assured, your personal and professional information is safe with us.`,
        label: "how_do_you_ensure_confidentiality",
    },
];

const FAQSection = forwardRef((props, ref) => {
    const [expandedFAQ, setExpandedFAQ] = useState(null);

    const handleFAQClick = (label, index) => {
        // Send event to Google Analytics
        if (typeof window.gtag === 'function') {
            window.gtag('event', 'faq_question_click', {
                event_category: 'faq_interaction',
                event_label: label,
                component_name: 'faq_section',
            });
        } else {
            console.warn('Google Analytics is not initialized');
        }

        // Toggle the expanded FAQ
        setExpandedFAQ(expandedFAQ === index ? null : index);
    };

    return (
        <FAQSectionWrapper ref={ref}>
            <SectionTitle>FAQ</SectionTitle>
            <FAQList>
                {faqs.map((faq, index) => {
                    const htmlAnswer = DOMPurify.sanitize(marked.parse(faq.answer));
                    return (
                        <FAQItem key={index}>
                            <Question onClick={() => handleFAQClick(faq.label, index)}>
                                {faq.question}
                                <ToggleIcon $isExpanded={expandedFAQ === index}>
                                    <FaChevronDown />
                                </ToggleIcon>
                            </Question>
                            {expandedFAQ === index && (
                                <>
                                    <Separator />
                                    <Answer>
                                        <AnswerContent dangerouslySetInnerHTML={{ __html: htmlAnswer }} />
                                    </Answer>
                                </>
                            )}
                        </FAQItem>
                    );
                })}
            </FAQList>
        </FAQSectionWrapper>
    );
});

export default FAQSection;

// Styled Components
const FAQSectionWrapper = styled.section`
    background-color: #fcfcfe;
    padding: 80px 20px;
    text-align: center;
    @media (max-width: 991px) {
        padding: 60px 20px;
    }
`;

const SectionTitle = styled.h2`
    color: #009379;
    font: 700 38px/1.3 'Poppins', sans-serif;
    letter-spacing: -0.76px;
    margin: 20px 0 50px;
    @media (max-width: 768px) {
        font-size: 32px;
    }
`;

const FAQList = styled.div`
    max-width: 900px;
    margin: 0 auto;
    text-align: left;
`;

const FAQItem = styled.div`
    background-color: #fff;
    border: 1px solid #dcdcdc;
    border-radius: 10px;
    margin-bottom: 20px;
    overflow: hidden;
    transition: all 0.3s ease;
`;

const Question = styled.button`
    background-color: #fff;
    color: #2d2d2d;
    cursor: pointer;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font: 600 22px/1.5 'Poppins', sans-serif;
    padding: 20px 30px;
    border: none;
    outline: none;
    text-align: left;
    &:hover {
        background-color: #f0faf8;
    }
`;

const ToggleIcon = styled.span`
    font-size: 20px;
    color: #009379;
    transition: transform 0.3s ease;
    transform: ${(props) => (props.$isExpanded ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const Separator = styled.div`
    height: 1px;
    background-color: #e0e0e0;
    margin: 0 30px;
`;

const Answer = styled.div`
    background-color: #fafbfc;
`;

const AnswerContent = styled.div`
    color: #555;
    font: 400 18px/1.8 'Mulish', sans-serif;
    padding: 25px 30px;
    margin: 0;
    h3,
    h4 {
        font-weight: 600;
        color: #2d2d2d;
        margin-top: 20px;
    }
    p {
        margin-bottom: 15px;
    }
    ul,
    ol {
        padding-left: 20px;
        margin-top: 10px;
        margin-bottom: 15px;
    }
    li {
        margin-bottom: 8px;
        line-height: 1.6;
    }
    strong,
    b {
        font-weight: 600;
        color: #2d2d2d;
    }
    a {
        color: #009379;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
`;
