import React from "react";
import styled from "styled-components";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

const metricsData = [
    {
        value: 200,
        label: "Careers Kickstarted",
    },
    {
        value: 100,
        label: "Promotions and Career Goals Achieved",
    },
    {
        value: 50,
        label: "Leaders and Professionals Uplifted",
    },
    {
        value: 400,
        label: "Interview Successes Secured",
    },
];

const MetricsSection = () => {
    const { ref, inView } = useInView({
        triggerOnce: false, // Allow animation to trigger every time it's in view
        threshold: 0.5,
    });

    return (
        <MetricsSectionWrapper ref={ref}>
            <SectionTitle>We're Here to Help You Succeed</SectionTitle>
            <SectionSubtitle>
                We understand the challenges you face, and our tailored coaching packages have helped countless professionals overcome them.
            </SectionSubtitle>
            <MetricsGrid>
                {metricsData.map((metric, index) => (
                    <MetricItem key={index}>
                        <MetricValue>
                            {inView ? (
                                <CountUp start={0} end={metric.value} duration={2} suffix="+" />
                            ) : (
                                `${metric.value}+`
                            )}
                        </MetricValue>
                        <MetricLabel>{metric.label}</MetricLabel>
                    </MetricItem>
                ))}
            </MetricsGrid>
        </MetricsSectionWrapper>
    );
};

const MetricsSectionWrapper = styled.section`
    background-color: #f8f9ff;
    padding: 80px 20px;
    text-align: center;
    @media (max-width: 991px) {
        padding: 60px 20px;
    }
`;

const SectionTitle = styled.h2`
    color: #009379;
    font: 700 38px/1.3 Poppins, sans-serif;
    letter-spacing: -0.76px;
    margin: 20px 0;
    @media (max-width: 768px) {
        font-size: 32px;
    }
`;

const SectionSubtitle = styled.p`
    color: #2d2d2d;
    font: 400 20px/1.5 'Mulish', sans-serif;
    margin: 20px auto 50px;
    max-width: 800px;
    font-style: italic;
    @media (max-width: 991px) {
        font-size: 18px;
        margin-bottom: 40px;
    }
`;

const MetricsGrid = styled.div`
    display: flex;
    justify-content: center;
    gap: 30px;
    @media (max-width: 991px) {
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }
`;

const MetricItem = styled.div`
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 20px;
    box-shadow: 0 10px 20px rgba(51, 102, 255, 0.05);
    padding: 40px 30px;
    width: 100%;
    max-width: 300px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-10px);
        box-shadow: 0 15px 25px rgba(51, 102, 255, 0.1);
    }

    @media (max-width: 991px) {
        max-width: 80%;
        padding: 30px 20px;
    }
`;

const MetricValue = styled.div`
    font: 700 38px/1.3 Poppins, sans-serif;
    color: #009379;
    margin-bottom: 12px;
    @media (max-width: 991px) {
        font-size: 32px;
    }
`;

const MetricLabel = styled.div`
    font: 400 16px/1.6 Mulish, sans-serif;
    color: #2d2d2d;
`;

export default MetricsSection;
