import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import Toaster from "./helpers/Toaster";
import { sendDataToLambda } from "./helpers/sendDataToLambda";

const landingPageData = {
    title: "Empower Your Tech Career with Expert Guidance and Empathy",
    description:
        "Unlock Your Potential: Master Tech Interviews and Career Growth with DrillUp",
    ctaText: "Schedule a Free Strategy Session",
    ctaLink: "#",
    secondaryCtaText: "Transform Your Future Now",
    secondaryCtaLink: "#",
};

const emailType = {
    access_to_slack: "Access to Slack Community",
    personalized_interview_prep: "Personalized Interview Prep",
    career_advancement_coaching: "Career Advancement Coaching",
    end_to_end_career_guidance: "End-to-End Career Guidance",
}

const LandingPage = ({ scrollToPricing }) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [sessionType, setSessionType] = useState("access_to_slack");
    const [submitted, setSubmitted] = useState(false);

    const [showToaster, setShowToaster] = useState(false);

    const handleCTAClick = (label, componentName, selectedCoachingType = '') => {
        if (typeof window.gtag === 'function') {
            window.gtag('event', 'cta_click', {
                event_category: 'landing',
                event_label: label,
                component_name: componentName,
                coaching_type: selectedCoachingType,
            });
        } else {
            console.warn('Google Analytics is not initialized');
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        handleCTAClick('book_your_free_consultation', 'small_form', sessionType);
        try {
            await sendDataToLambda({ name, email, type: sessionType, emailType: emailType[sessionType] });
            setSubmitted(true);
            setShowToaster(true);
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    return (
        <LandingPageWrapper>
            <LandingPageContent>
                <LandingPageInfo>
                    <LandingPageTitle>{landingPageData.title}</LandingPageTitle>
                    <LandingPageDescription>{landingPageData.description}</LandingPageDescription>
                    <LandingPageCTAWrapper>
                        <LandingPageSecondaryCTA
                            href="#"
                            onClick={(event) => {
                                scrollToPricing(event);
                                handleCTAClick('transform_your_future_now', 'landing');
                            }}
                        >
                            {landingPageData.secondaryCtaText}
                        </LandingPageSecondaryCTA>
                    </LandingPageCTAWrapper>
                </LandingPageInfo>
                <LandingPageFormWrapper>
                    <BookingForm onSubmit={handleFormSubmit}>
                        <FormTitle>Book Free Consultation Now!</FormTitle>
                        <FormGroup>
                            <Label htmlFor="name">Your Name <Asterisk>*</Asterisk></Label>
                            <Input
                                type="text"
                                id="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Enter your name"
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="email">Your Email <Asterisk>*</Asterisk></Label>
                            <Input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter your email"
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="sessionType">Type of Coaching <Asterisk>*</Asterisk></Label>
                            <Select
                                id="sessionType"
                                value={sessionType}
                                onChange={(e) => setSessionType(e.target.value)}
                                required
                            >
                                <option value="access_to_slack">Access to Slack Community</option>
                                <option value="personalized_interview_prep">Personalized Interview Prep</option>
                                <option value="career_advancement_coaching">Career Advancement Coaching</option>
                                <option value="end_to_end_career_guidance">End-to-End Career Guidance</option>
                            </Select>
                        </FormGroup>
                        <SubmitButton type="submit">
                            {submitted ? "Thank You! We'll be in Touch" : "Book Your Free Consultation"}
                        </SubmitButton>
                        <Toaster
                            message="Your Form Has Been Successfully Submitted!"
                            show={showToaster}
                            onClose={() => {setShowToaster(false); setEmail(''); setName(''); setSessionType('personalized_interview_prep'); setSubmitted(false);}}
                        />
                    </BookingForm>
                </LandingPageFormWrapper>
            </LandingPageContent>
        </LandingPageWrapper>
    );
};

// Animations
const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

// Styled Components
const LandingPageWrapper = styled.section`
    background-color: #f8f9ff;
    padding: 60px 20px 20px 20px;  /* Removed top padding to move content up */
    display: flex;
    justify-content: center;
    min-height: 70vh;
`;

const LandingPageContent = styled.div`
    display: flex;
    flex-direction: row;
    gap: 60px;
    width: 100%;
    max-width: 1200px;
    //align-items: center;
    animation: ${fadeIn} 1s ease-out;
    margin-top: 0; /* Ensure no extra margin at the top */

    @media (max-width: 768px) {
        flex-direction: column;
        text-align: center;
    }
`;

const LandingPageInfo = styled.div`
    flex: 1;
`;

const LandingPageTitle = styled.h1`
    color: #2d2d2d;
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        font-size: 2.5rem;
    }
`;

const LandingPageDescription = styled.p`
    color: #2d2d2d;
    font: 400 20px/1.5 'Mulish', sans-serif; /* Increased font size and adjusted line height */
    margin: 20px auto 50px; /* Adjusted spacing for better balance */
    max-width: 800px; /* Set a maximum width to keep the text readable on larger screens */
    font-style: italic; /* Added a slight italic style for emphasis */
    line-height: 1.6;
    @media (max-width: 991px) {
        font-size: 18px; /* Slightly smaller on mobile */
        margin-top: 15px;
    }
`;

const LandingPageCTAWrapper = styled.div`
    display: flex;
    gap: 20px;
    margin-top: 40px;
    flex-wrap: wrap;
    justify-content: center;
`;

const LandingPageSecondaryCTA = styled.a`
    background-color: transparent;
    color: #009379;
    padding: 15px 30px;
    border-radius: 30px;
    border: 2px solid #009379;
    text-align: center;
    text-decoration: none;
    font-weight: 600;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;

    &:hover {
        background-color: #f1f1f1;
        color: #007b64;
        transform: translateY(-3px);
    }

    @media (max-width: 768px) {
        padding: 12px 25px;
    }
`;

const LandingPageFormWrapper = styled.div`
    flex: 1;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
`;

const FormTitle = styled.h3`
    color: #2d2d2d;
    font-size: 1.75rem;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: left;
    width: 100%;
`;

const BookingForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-width: 400px;
    background: #fff;
    border-radius: 15px;
    padding: 25px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left; /* Ensures the labels are aligned to the left */
`;

const Label = styled.label`
    font: 500 16px/1.3 Poppins, sans-serif;
    color: #2d2d2d;
    margin-bottom: 8px;
`;

const Asterisk = styled.span`
    color: red;
`;

const Input = styled.input`
    padding: 12px 16px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font: 400 16px/1.3 Poppins, sans-serif;
    color: #333;
    outline: none;

    &:focus {
        border-color: #009379;
    }
`;

const Select = styled.select`
    padding: 12px 16px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font: 400 16px/1.3 Poppins, sans-serif;
    color: #333;
    outline: none;

    &:focus {
        border-color: #009379;
    }
`;

const SubmitButton = styled.button`
    background-color: ${({ disabled }) => (disabled ? "#ccc" : "#009379")};
    color: #fff;
    padding: 14px 0;
    border-radius: 10px;
    border: none;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    &:hover {
        background-color: ${({ disabled }) => (disabled ? "#ccc" : "#007b64")};
        transform: translateY(-3px);
    }
`;

export default LandingPage;
